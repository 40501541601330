export default function analytics() {
  // Referrer feature: https://github.com/leancloud/uluru-platform/issues/3796
  const REFERRER_KEY = 'referrer';
  if (document.referrer && window.URL) {
    if (!new URL(document.referrer).hostname.match(/leancloud.cn$/)) {
      if (!localStorage.getItem(REFERRER_KEY)) {
        localStorage.setItem(
          REFERRER_KEY,
          JSON.stringify({
            value: document.referrer,
            date: Date.now()
          })
        );
      }
    }
  }

  const LANDING_URL_KEY = 'landingUrl';
  if (document.location.href) {
    if (!localStorage.getItem(LANDING_URL_KEY)) {
      localStorage.setItem(
        LANDING_URL_KEY,
        JSON.stringify({
          value: document.location.href,
          date: Date.now()
        })
      );
    }
  }

  // Promo feature from https://github.com/leancloud/avfr/pull/541
  const result = window.location.search.match(/[?&]source=([^&]*)/);

  if (result) {
    const source = decodeURIComponent(result[1]);
    localStorage.setItem(
      'promoCode',
      JSON.stringify({
        value: source,
        date: Date.now()
      })
    );

    let newLocation = window.location.pathname + window.location.search.replace(/(?=\?*)&*source=[^&]*&?/i, '');
    if (newLocation.indexOf('?') === newLocation.length - 1) {
      newLocation = newLocation.slice(0, newLocation.length - 1);
    }
    window.history.replaceState(null, null, newLocation);

    $.ajax({
      method: 'POST',
      url: 'https://bkatfiy3.stats.lncld.net/1.1/functions/collect',
      headers: {
        'X-LC-Id': 'bkaTfiY3Nc2jLJW7hXRmoFiK',
        'X-LC-Key': 'FkooEOxbmkmSnlEvTjxPFEiy'
      },
      data: JSON.stringify({
        type: 'LINK_CLICKED',
        payload: {
          promoteCode: source,
          referrer: document.referrer
        }
      }),
      contentType: 'application/json'
    });
  }
}
