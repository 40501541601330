import {
  formatFloat,
  getExchangeRate,
  localStorageSet,
  localStorageGet,
  formatCurrency
} from './tools';

const priceConfig = [
  // China region
  {
    currency: 'RMB',
    currency_unit: '¥',
    prices: {
      dataApi: {
        price: 1.0,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [500, 100],
          '80%': [1000, 500],
          max: [5000]
        }
      },
      dataStorage: {
        price: 0.1,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [50],
          '80%': [500, 10],
          max: [5000]
        }
      },
      fileStorage: {
        price: 0.0053,
        freeTier: 10,
        startVal: 0,
        range: {
          min: [0],
          '50%': [500, 1000],
          '80%': [10000, 5000],
          max: [100000]
        }
      },
      liveQueryMessage: {
        price: 2,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [100],
          '80%': [1000, 100],
          max: [5000]
        }
      },
      liveQuerySubscriptions: {
        price: 4,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [100],
          '80%': [1000, 100],
          max: [5000]
        }
      },
      fileBandwidth: {
        price: 0.3,
        freeTier: 0.5,
        startVal: 0,
        range: {
          min: [0],
          '50%': [500, 500],
          '80%': [4000, 1000],
          max: [10000]
        }
      },
      messageRealtime: {
        price: 15,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [100, 10],
          '80%': [250, 50],
          max: [1000]
        }
      },
      messageSms: {
        price: 0.05,
        freeTier: 0,
        freeOneTime: 100,
        startVal: 0,
        range: {
          min: [0],
          '50%': [5000, 100],
          '80%': [100000, 1000],
          max: [1000000]
        }
      },
      leanEngine: {
        price: {
          '512 MB / 1 CPU': 2,
          '1 GB / 1 CPU': 4,
          '2 GB / 1 CPU': 8,
          '4 GB / 1 CPU': 16
        },
        freeTier: 0,
        freeOneTime: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [20, 5],
          max: [100]
        }
      },
      leanCache: {
        price: {
          '128 MB': 1,
          '256 MB': 1.5,
          '512 MB': 3,
          '1 GB': 5,
          '2 GB': 10,
          '4 GB': 20,
          '8 GB': 50
        },
        freeTier: 0,
        freeOneTime: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [20, 5],
          max: [100]
        }
      }
    }
  },

  // US region
  {
    currency: 'USD',
    currency_unit: '$',
    prices: {
      dataApi: {
        price: 0.4,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [500, 100],
          '80%': [1000, 500],
          max: [5000]
        },
        unit: ['request/day', 'requests/day'],
        multiple: 10000
      },
      dataStorage: {
        price: 0.05,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [50],
          '80%': [500, 10],
          max: [5000]
        },
        unit: ['GB/day', 'GB/day'],
        multiple: 1
      },
      fileStorage: {
        price: 0.001,
        freeTier: 10,
        startVal: 0,
        range: {
          min: [0],
          '50%': [500, 1000],
          '80%': [10000, 5000],
          max: [100000]
        },
        unit: ['GB/day', 'GB/day'],
        multiple: 1
      },
      fileBandwidth: {
        price: 0.1,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [500, 500],
          '80%': [4000, 1000],
          max: [10000]
        },
        unit: ['GB/day', 'GB/day'],
        multiple: 1
      },
      liveQueryMessage: {
        price: 0.4,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [100],
          '80%': [1000, 100],
          max: [5000]
        },
        unit: ['message/day', 'messages/day'],
        multiple: 10000
      },
      liveQuerySubscriptions: {
        price: 1,
        freeTier: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [100],
          '80%': [1000, 100],
          max: [5000]
        },
        unit: ['subscription/day', 'subscriptions/day'],
        multiple: 1000
      },
      messageRealtime: {
        price: 10,
        freeTier: 0.0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [100, 10],
          '80%': [250, 50],
          max: [1000]
        },
        unit: ['user/day', 'users/day'],
        multiple: 10000
      },
      messageSms: {
        price: 0.015,
        freeTier: 0,
        freeOneTime: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [50000, 1000],
          '80%': [500000, 10000],
          max: [1000000]
        },
        unit: ['message/day', 'messages/day'],
        multiple: 1
      },
      leanEngine: {
        price: {
          '512 MB / 1 CPU': 2 * 0.8,
          '1 GB / 1 CPU': 4 * 0.8,
          '2 GB / 1 CPU': 8 * 0.8,
          '4 GB / 1 CPU': 16 * 0.8
        },
        freeTier: 0,
        freeOneTime: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [20, 5],
          max: [100]
        },
        unit: ['instance', 'instances'],
        multiple: 1
      },
      leanCache: {
        price: {
          '128 MB': 0.4,
          '256 MB': 0.6,
          '512 MB': 1,
          '1 GB': 2,
          '2 GB': 4,
          '4 GB': 8,
          '8 GB': 20
        },
        freeTier: 0,
        freeOneTime: 0,
        startVal: 0,
        range: {
          min: [0],
          '50%': [20, 5],
          max: [100]
        },
        unit: ['instance', 'instances'],
        multiple: 1
      }
    }
  }
];
function compressNumber(number) {
  const numberInString = number.toFixed(0).toString();
  let result;
  if (numberInString.endsWith('000000')) {
    result = `${numberInString / 1000000}M`;
  } else if (numberInString.endsWith('000')) {
    result = `${numberInString / 1000}K`;
  } else {
    result = numberInString;
  }
  return result;
}
const productsSlug = Object.keys(priceConfig[0].prices);
const independencePrice = ['fileStorage', 'fileBandwidth'];
const region = 'intl';
let panelButton = 'price';
const availablePanel = ['price', 'calculator', 'faq'];
// tab init
function tabInit() {
  $(`.${panelButton}-button`).addClass('active');
  $(`#${panelButton}-panel`).addClass('active');
  // eslint-disable-next-line
  if (!$._data($('.tab')[0], 'events')) {
    $('.tab').on('click', (e) => {
      e.stopPropagation();
      if ($(e.target).hasClass('active')) {
        return;
      }
      $(e.target)
        .addClass('active')
        .siblings('a')
        .removeClass('active');
      $(`#${e.target.dataset.key}-panel`)
        .addClass('active')
        .siblings('div.panel')
        .removeClass('active');
    });
  }
}

function updatePriceDom(init) {
  const initSlider = init;
  let products = {};
  const currency = {};
  const priceSet = priceConfig;

  if (region === 'cn') {
    products = priceSet[0].prices;
    currency.long = priceSet[0].currency;
    currency.short = priceSet[0].currency_unit;
  } else {
    products = priceSet[1].prices;
    currency.long = priceSet[1].currency;
    currency.short = priceSet[1].currency_unit;
  }
  Object.keys(products).forEach((key) => {
    const value = products[key];
    const $tag = $(`.price-calc-${key}`);
    const $slider = $tag.find('.custom-slider')[0];
    const { startVal } = value;
    let price = 0;
    if (initSlider) {
      noUiSlider.create($slider, {
        connect: [true, false],
        start: [startVal],
        step: 1,
        range: value.range
      });
    }
    if (typeof value.price === 'object') {
      const priceTiers = value.price;
      price = priceTiers[Object.keys(priceTiers)[0]];
      // Apply price to radio DOM
      for (let i = 0; i <= $tag.find('.price-calc-tier input').length; i += 1) {
        const el = $tag.find('.price-calc-tier input')[i];
        $(el).val(priceTiers[Object.keys(priceTiers)[i]]);
      }
      // Read current active tier
      const activeTierPrice = $tag.find('.price-calc-tier input:checked').val();
      // Apply initial price
      $tag.find('.price-tag').text(formatFloat(activeTierPrice));
    } else {
      // eslint-disable-next-line
      price = value.price;
      // Apply price
      $tag.find('.price-tag').text(formatFloat(price));
      // const $formInput = $tag.find('.price-calc-input .input');
      // const $formResult = $tag.find('.price-calc-result .result');
    }
  });
  // Update price currency
  $('.price-calc-heading .currency').text(currency.short);
  $('.price-calc-result .currency').text(currency.long);
}

// Calc total price
function totalPriceByEleArray(eleArray, totalPrice) {
  return eleArray
    .map((v) => {
      const el = $(`.price-calc-${v} .price-calc-result .result`);
      const val = Number($(el).attr('data-result'));
      return Number.isNaN(val) ? 0 : val;
    })
    .reduce((total, currentValue) => Number(total) + Number(currentValue), totalPrice);
}
function calcTotalPrice() {
  let totalPrice = totalPriceByEleArray(
    productsSlug.filter((v) => independencePrice.indexOf(v) === -1),
    0
  );
  const totalIndepPrice = totalPriceByEleArray(independencePrice, 0);
  if (region === 'cn') {
    totalPrice = totalPrice < 30 ? 30 + totalIndepPrice : totalPrice + totalIndepPrice;
  } else {
    totalPrice = totalPrice < 5 ? 5 + totalIndepPrice : totalPrice + totalIndepPrice;
  }
  $('.price-calc-result .total').text(formatCurrency(formatFloat(totalPrice)));
}

function updatePrice(product, amount) {
  // set proper region
  const priceSet = region !== 'cn' ? priceConfig[1] : priceConfig[0];
  const $resultTag = $(`.price-calc-${product} .price-calc-result .result`);
  const { freeTier } = priceSet.prices[product];
  let productPrice = priceSet.prices[product].price;
  if (typeof productPrice === 'object') {
    productPrice = $(`.price-calc-${product} .price-calc-tier input:checked`).val();
    $(`.price-calc-${product}`)
      .find('.price-tag')
      .text(formatFloat(productPrice));
  }

  // if no amount passed through parent...
  // then get the existing value, and force update it.
  // useful when switching to another region.
  if (!amount && amount !== 0) {
    amount = $(`.price-calc-${product} .price-calc-input .input`).val();
  }
  const resultPrice = (amount - freeTier) * productPrice;

  // update input value
  $(`.price-calc-${product} .price-calc-input .input`).val(amount); // hidden on UI; only for storing value
  $(`.price-calc-${product} .price-calc-input span`).text(
    `${compressNumber(amount * priceSet.prices[product].multiple)} ${(
      amount * priceSet.prices[product].multiple === 1
        ? priceSet.prices[product].unit[0]
        : priceSet.prices[product].unit[1]
    )}`
  );

  // update price result
  if (freeTier < amount) {
    $resultTag.text(formatCurrency(resultPrice)).attr('data-result', resultPrice);
    if (resultPrice >= 10000) {
      $resultTag.addClass('big-fig');
    } else {
      $resultTag.removeClass('big-fig');
    }
  } else {
    $resultTag.text('0.00').attr('data-result', '0');
  }
  // call calc total price
  calcTotalPrice();
}
function handleHashChange() {
  if (window.location.hash) {
    const hash = window.location.hash.replace(/^#/, '');
    if (hash && availablePanel.indexOf(hash) > -1) {
      panelButton = hash;
    }
  }
}

export default function priceInit() {
  window.onhashchange = handleHashChange;
  handleHashChange();
  tabInit(panelButton);
  // 获取汇率
  let rate = localStorageGet('exchange-rate');
  if (!rate) {
    getExchangeRate()
      .done((data) => {
        rate = data.us.exch_rate;
        localStorageSet('exchange-rate', {
          value: rate
        });
        $('#exchange-rate').text(rate);
      })
      .fail(() => {
        throw Error('Cannot fetch exchange rate');
      });
  } else {
    $('#exchange-rate').text(rate);
  }

  // 计算器初始化
  updatePriceDom(true);

  function initPriceListen(product) {
    // when slider updates
    $(`.price-calc-${product} .custom-slider`)[0].noUiSlider.on('update', (values, handle) => {
      const amount = +values[handle];
      updatePrice(product, amount);
    });

    // ycui: this is for Google Ads conversion
    gtag('event', 'conversion', { send_to: 'AW-1030743864/TDDfCJ_C8K8BELjOv-sD' });

    // when product tier changes
    $(`.price-calc-${product} .price-calc-tier input`).on('change', function () {
      const amount = $(this)
        .closest('.price-calc')
        .find('.price-calc-input .input')
        .val();
      updatePrice(product, amount);
    });
  }

  // init price change listener
  for (let i = 0; i < productsSlug.length; i += 1) {
    initPriceListen(productsSlug[i]);
  }

  // 增加 input value 监听
  // $('.price-calc-input .input').on('input', function () {
  //   if ($(this).val() === '') {
  //     $(this).val(0);
  //   }
  //   $(this)
  //     .closest('.price-calc')
  //     .find('.price-calc-slider .custom-slider')[0]
  //     .noUiSlider.set(this.value);
  // });
}
