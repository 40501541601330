import { exampleSwiper, addActive, navInit } from './js/tools';
import AceEditor from './js/ace';
import pricing from './js/pricing';
import analytics from './js/analytics';

/**
 * 所有页面执行的初始化
 * 1. 为手机菜单添加 click 事件
 * 2. 为 header 头添加 shadow
 * 3. 根据 url 初始化下标
 */

function mainInit() {
  // 初始化菜单按钮 click 事件
  $('.wrapper-header .menu').on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    $('.wrapper-header .menu').toggleClass('active');
    $('.site-nav').toggleClass('active');
    // 当菜单按钮关闭时候 同时关闭内部2级菜单
    if (!$('.wrapper-header .menu').hasClass('active')) {
      $('.site-nav-item').removeClass('active');
    }
  });

  $('.site-nav-dropdown').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this)
      .parent()
      .toggleClass('active');
  });

  // 阻止菜单 click 事件冒泡
  $('.site-nav').on('click', (e) => {
    e.stopPropagation();
  });
  // 点击 body 关闭菜单
  $('body').on('click', () => {
    $('.wrapper-header .menu').removeClass('active');
    $('.site-nav').removeClass('active');
    $('.site-nav-item').removeClass('active');
  });

  navInit();
  exampleSwiper();
  try {
    analytics();
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

// index page
function index() {
  // banner init
  // eslint-disable-next-line
  new Swiper('#banner-swiper', {
    autoplay: {
      delay: 6000
    },
    loop: true,
    pagination: {
      el: '.banner-pagination',
      clickable: true
    }
  });
  // edit init
  const editor = new AceEditor('editor');
  $('.editor-nav ul').click((e) => {
    e.preventDefault();
    e.stopPropagation();
    addActive(e.target, 'li');
    if (e.target.dataset.key) {
      editor.updateLang(e.target.dataset.key);
    }
  });
  $('.editor-doc-select').change(function () {
    const options = $(this).children('option:selected')[0];
    if (options.dataset.key) {
      editor.updateType(options.dataset.key);
    }
  });
  $('.editor-doc').on('click', (e) => {
    e.stopPropagation();
    addActive(e.target, 'li');
    if (e.target.dataset.key) {
      editor.updateType(e.target.dataset.key);
    }
  });
}
function tutorials() {
  // banner init
  // eslint-disable-next-line
  new Swiper('.tutorials-container', {
    autoplay: {
      delay: 6000
    },
    loop: true,
    pagination: {
      el: '.hero-pagination',
      clickable: true
    }
  });
}

window.init = (name) => {
  $(() => {
    const maps = {
      index,
      pricing,
      tutorials
    };
    mainInit();
    if (maps[name]) {
      maps[name]();
    }
  });
};
window.targetHref = (href) => {
  window.open(href);
};
