// 数据模型与存储代码
const storage = {
  Curl: `// how to save a new object.
curl -X POST
  -H "X-LC-Id: {yourAppId}"
  -H "X-LC-Key: {yourAppKey}"
  -H "Content-Type: application/json"
  -d '{"content": "The 8 Tools Every Java Developer Needs to Know","pubUser": "LeanCloud Support","pubTimestamp": 1435541999}'
https://zep7vtap.api.lncld.net/1.1/classes/Post`,

  Java: `AVObject post = new AVObject("Post");
post.put("content", "The 8 Tools Every Java Developer Needs to Know");
post.put("pubUser", "LeanCloud Support");
post.put("pubTimestamp", 1435541999);
post.saveInBackground();  // save to LeanCloud`,

  JS: `var Post = AV.Object.extend('Post');
// create a new post instance.
var post = new Post();
post.set('content','The 8 Tools Every Java Developer Needs to Know');
post.set('pubUser', 'LeanCloud Support');
post.set('pubTimestamp',1435541999);
post.save().then(function (post) {
  console.log('objectId is ' + post.id);
}, function (error) {
  console.error(error);
});`,

  'Obj-C': `AVObject *post = [[AVObject alloc] initWithClassName:@"Post"];
[post setObject:@"The 8 Tools Every Java Developer Needs to Know" forKey:@"content"];
[post setObject:@"LeanCloud Support" forKey:@"pubUser"];
[post setObject:@1435541999 forKey:@"pubTimestamp"];
[post saveInBackground];  // save to LeanCloud`,

  Swift: `let post = LCObject(className: "Post")
post.set("content", value: "The 8 Tools Every Java Developer Needs to Know")
post.set("pubUser", value: "LeanCloud Support")
post.set("pubTimestamp", value: 1435541999)
post.save { result in
  switch result {
    case .success:
      break
    case .failure(let error):
      print(error)
  }
}`,

  'C#': `AVObject post = new AVObject("Post");
post["content"] = "The 8 Tools Every Java Developer Needs to Know";
post["pubUser"] = "LeanCloud Support";
post["pubTimestamp"] = 1435541999;
Task saveTask = post.SaveAsync();
await saveTask;`,

  Python: `import leancloud
Post = leancloud.Object.extend('Post')
post = Post()
post.set('pubUser', 'LeanCloud Support')
post.set('content', 'The 8 Tools Every Java Developer Needs to Know')
post.set('pubTimestamp', 1435541999)
post.save()`,

  PHP: `use LeanCloudLeanObject;

$post = new LeanObject("Post");
$post->set('pubUser', 'LeanCloud Support');
$post->set('content', 'The 8 Tools Every Java Developer Needs to Know');
$post->set('pubTimestamp', 1435541999);
try {
  $post->save();
} catch (CloudException $ex) {
  // handle exception.
}`
};
// 查询代码
const search = {
  Curl: `curl -X GET
  -H "X-LC-Id: {yourAppId}"
  -H "X-LC-Key: {yourAppKey}"
  -H "Content-Type: application/json"
https://zep7vtap.api.lncld.net/1.1/classes/Post`,

  Java: `AVQuery<AVObject> query = new AVQuery<>("Post");
query.setCachePolicy(AVQuery.CachePolicy.NETWORK_ELSE_CACHE);
query.setMaxCacheAge(24 * 3600 * 1000);
query.findInBackground(new FindCallback<AVObject>() {
  @Override
  public void done(List<AVObject> list, AVException e) {
    if (e == null) {
      //...
    } else {
      // handle exception.
    }
  }
});`,

  JS: `var query = new AV.Query('Post');
// retrieve Post instance with specified objectId.
query.get('57328ca079bc44005c2472d0').then(function (post) {
  // Success
}, function (error) {
  // handle exception
});`,

  'Obj-C': `AVQuery *query = [AVQuery queryWithClassName:@"Post"];
// retrieve Post instance with specified objectId.
[query getObjectInBackgroundWithId:@"57328ca079bc44005c2472d0" block:^(AVObject *object, NSError *error) {
  // ...
}];`,

  Swift: `let query = LCQuery(className: "Post")
query.get("57328ca079bc44005c2472d0") { result in
  switch result {
    case .success(let post):
      print(post.get("content"))
    case .failure(let error):
      print(error)
  }
}`,

  'C#': `AVQuery<AVObject> query=new AVQuery<AVObject>("Post").WhereEqualTo ("pubUser", "LeanCloud Support");
await query.FindAsync ().ContinueWith (t => {
  IEnumerable<AVObject> persons=t.Result;
  int sum=persons.Count();
});`,

  Python: `import leancloud
query = leancloud.Query('Post')

# retrieve Post instance with specified objectId.
query_result = query.get('57328ca079bc44005c2472d0')
content = query_result.get('content')`,

  PHP: `$query = new Query("Post");
$post  = $query->get("57328ca079bc44005c2472d0");
// Success`
};

const engine = {
  Curl: `// how to call cloud function.
curl -X POST
  -H "X-LC-Id: {yourAppId}"
  -H "X-LC-Key: {yourAppKey}"
  -H "Content-Type: application/json"
  -d '{"movie": "Despicable Me"}'
https://zep7vtap.engine.lncld.net/1.1/functions/averageStars`,

  Java: `Map<String, String> dicParameters = new HashMap<String, String>();
dicParameters.put("movie", "Despicable Me");

// call cloud function: averageStars
AVCloud.callFunctionInBackground("averageStars", dicParameters, new FunctionCallback() {
  public void done(Object object, AVException e) {
    if (e == null) {
      //...
    } else {
      // handle exception
    }
  }
});`,

  JS: `var paramsJson = {
  movie: "Despicable Me"
};
AV.Cloud.run('averageStars', paramsJson).then(function(data) {
  // Success
}, function(err) {
  // handle exception
});`,

  'Obj-C': `NSDictionary *dicParameters = [NSDictionary dictionaryWithObject:@"Despicable Me"
                                                          forKey:@"movie"];

// call cloud function: averageStars
[AVCloud callFunctionInBackground:@"averageStars"
                    withParameters:dicParameters
                    block:^(id object, NSError *error) {
                    if(error == nil){
                      // succeeded
                    } else {
                      // handle exception
                    }
}];`,

  Swift: '// Not supported yet',
  'C#': '// Not supported yet',

  Python: `from leancloud import cloudfunc
cloudfunc.run('averageStars', movie='Despicable Me')`,

  PHP: `use LeanCloudEngineCloud;
$params = array(
    "movie" => "Despicable Me"
);
Cloud::run("averageStars", $params);`
};
const rtm = {
  Curl: `// how to send message through particular conversation.
curl -X POST
  -H "X-LC-Id: {yourAppId}"
  -H "X-LC-Key: {yourAppMasterKey}"
  -H "Content-Type: application/json"
  -d '{"from_client": "me", "message": "hello"}'
https://zep7vtap.api.lncld.net/1.2/rtm/conversations/{conv_id}/messages`,

  Java: `conversation.sendMessage(msg, new AVIMConversationCallback() {
  @Override
  public void done(AVIMException e) {
    if (e == null) {
      Log.d("Tom & Jerry", "done！");
    }
  }
});`,

  JS: `var { TextMessage } = require('leancloud-realtime');
realtime.createIMClient('Tom').then(function(tom) {
  // create a conversation with members: tom and jerry.
  return tom.createConversation({
    members: ['Jerry'],
    name: 'Tom & Jerry',
  });
}).then(function(conversation) {
  return conversation.send(new TextMessage('Jerry, get up!'));
}).then(function(message) {
  console.log('Tom & Jerry', 'done!');
}).catch(console.error);`,

  'Obj-C': `[conversation sendMessage:[AVIMTextMessage messageWithText:@"Jerry, get up!" attributes:nil] callback:^(BOOL succeeded, NSError *error) {
    if (succeeded) {
      NSLog(@"done!");
    }
}];`,

  Swift: '// Not supported yet',
  'C#': `// "EaterNation" is an AVIMConversation instance
var EaterNationConversation = x.Result;
// create a text message.
var textMessage = new AVIMTextMessage("Guyz let's rock it!");
return EaterNationConversation.SendMessageAsync(textMessage);`,

  Python: '// Not supported yet',
  PHP: '// Not supported yet'
};
const gamer = {
  Curl: `// how to query leaderboard
curl -X GET
  -H "X-LC-Id: {yourAppId}"
  -H "X-LC-Key: {yourAppMasterKey}"
https://zep7vtap.api.lncld.net/1.1/leaderboard/leaderboards/<statisticName>`,
  JS: `var leaderboard = AV.Leaderboard.createWithoutData('score');
leaderboard.getResults({
  limit: 10,
  selectUserKeys: ['username', 'age'], // include username and age attributes.
}).then(function(rankings) {
  //...
}).catch(console.error);`,

  Java: '// Not supported yet;',
  'Obj-C': '// Not supported yet;',
  Swift: '// Not supported yet;',
  'C#': '// Not supported yet;',
  Python: '// Not supported yet;',
  PHP: '// Not supported yet;'
};
export default {
  storage,
  search,
  engine,
  rtm,
  gamer
};
