// index ace editor js
import initCode from './code';

const langOptMap = {
  Curl: {
    mode: 'ace/mode/dart'
  },
  Java: {
    mode: 'ace/mode/java'
  },
  JS: {
    mode: 'ace/mode/javascript'
  },
  'Obj-C': {
    mode: 'ace/mode/objectivec'
  },
  Swift: {
    mode: 'ace/mode/swift'
  },
  'C#': {
    mode: 'ace/mode/csharp'
  },
  Python: {
    mode: 'ace/mode/python'
  },
  PHP: {
    mode: 'ace/mode/haxe'
  }
};

export default class AceEditor {
  constructor(elementId) {
    this.lang = 'Curl';
    this.type = 'storage';
    this.theme = 'ace/theme/textmate';
    ace.require('ace/ext/language_tools');
    this.editor = ace.edit(elementId || 'editor');
    this.editor.setOptions({
      showPrintMargin: false,
      theme: this.theme,
      fontSize: 14,
      wrap: true
    });
    // 关闭在线检测语法
    this.editor.session.setOption('useWorker', false);
    // 设置 true 只读
    this.editor.setReadOnly(true);
    this.upDateEditor();
  }

  updateLang(lang) {
    lang = String.prototype.trim.call(lang);
    if (Object.keys(langOptMap).indexOf(lang) === -1) {
      // eslint-disable-next-line no-console
      console.log('ace editor lang is not allow, lang:', lang);
      return;
    }
    this.lang = lang;
    this.upDateEditor();
  }

  updateType(type) {
    type = String.prototype.trim.call(type);
    if (Object.keys(initCode).indexOf(type) === -1) {
      // eslint-disable-next-line no-console
      console.log('ace editor type is not allow, type:', type);
      return;
    }
    this.type = type;
    this.upDateEditor();
  }

  upDateEditor() {
    this.setOpt(this.lang);
    const value = initCode[this.type][this.lang];
    this.setValue(value);
  }

  setOpt(lang) {
    const opt = langOptMap[lang];
    if (opt && opt.mode) {
      this.editor.getSession().setMode(opt.mode);
    }
    if (opt && opt.theme) {
      this.theme = opt.theme;
      this.editor.setTheme(opt.theme);
    }
  }

  setValue(value, isSelect = -1) {
    value = value || 'none';
    this.editor.setValue(value, isSelect);
  }
}
